import { Container, Row, Col, Image } from "react-bootstrap"
import { motion } from "framer-motion";

const Intro = () => {
    return (
        <Container className='intro-container pt-1 pb-5'>
            <Row className="text-center pb-2">
                <Col>
                    <span className="intro-h1">
                        I Am...
                    </span>
                </Col>
            </Row>
            <Row className='text-center intro-row pt-md-5'>
                <Col xs='12' md='6' xl='4' className='offset-md-0 offset-lg-0 intro-col px-4'>
                    <motion.div
                        initial={{ opacity: 0, y: 100 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 1, ease: "easeOut" }}
                        viewport={{ once: true }}
                    >
                        <div className="intro-img-container">
                            <Image className="intro-img" src={require("../../assets/images/maker_converted copy.jpg")} alt="maker image" />
                            <div className="intro-img-overlay"><span className='intro-h2'>A Maker</span></div>
                        </div>
                        <p className='text-center border-top'>I have extensive experience building interactive technology from scratch. I spent over 6 years working in the museum exhibit industry creating exciting displays for guests to experience. Technologies include microcontrollers, animatronic dinosaurs, virtual reality, and gesture tracking.</p>
                    </motion.div>
                </Col>
                <Col xs='12' md='6' xl='4' className='pt-5 pt-md-0 intro-col px-4'>
                    <motion.div
                        initial={{ opacity: 0, y: 100 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 1, ease: "easeOut" }}
                        viewport={{ once: true }}
                    >
                        <div className="intro-img-container">
                            <Image className="intro-img" src={require("../../assets/images/itguy2_converted.jpg")} alt="maker image" />
                            <div className="intro-img-overlay"><span className='intro-h2'>An IT Guy</span></div>
                        </div>
                        <p className='text-center border-top'>Apart from my years as a dinosaur mechanic, I have over three years of professional experience providing IT support in an enterprise environment. I have been trained and credentialed by experts to troubleshoot and resolve issues with networks, servers, and various user systems and devices.</p>
                    </motion.div>
                </Col>
                <Col xs='12' md='6' xl='4' className='offset-md-3 offset-xl-0 pt-5 intro-col px-4 pt-xl-0'>
                    <motion.div
                        initial={{ opacity: 0, y: 100 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 1, ease: "easeOut" }}
                        viewport={{ once: true }}
                    >
                        <div className="intro-img-container">
                            <Image className="intro-img" src={require("../../assets/images/developer2 copy.jpg")} alt="maker image" />
                            <div className="intro-img-overlay"><span className='intro-h2'>A Developer</span></div>
                        </div>
                        <p className='text-center border-top'>I have experience programming microcontrollers in C, developing full-stack Single Page Applications (SPAs) using the MERN stack, creating hybrid apps with React Native, and writing scripts in Command Line and PowerShell.</p>
                    </motion.div>
                </Col>
            </Row>
        </Container>
    )
}

export default Intro;