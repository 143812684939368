import { 
    createBrowserRouter,
    RouterProvider
} from 'react-router-dom';
import Layout from './components/LAYOUT';
import Home from './pages/HomePage';
import About from './pages/AboutPage';
import Services from './pages/ServicesPage';
import Contact from './pages/ContactPage';
import './App.css';

const router = createBrowserRouter([
    {
        path: '/',
        element: <Layout />,
        children: [
            {
                index: true,
                element: <Home />
            },
            {
                path: 'about',
                element: <About />
            },
            {
                path: 'services',
                element: <Services />
            },
            {
                path: 'contact',
                element: <Contact />
            }
        ]
    }
]);

const App = () => {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
