import {
    Col,
    FormGroup,
    Label,
    Button
} from 'reactstrap';
import {
    Formik,
    Form,
    Field,
    ErrorMessage
} from 'formik';
import { validateContactForm } from '../utils/validateContactForm';

const sendFormData = async (values) => {
    try {
        const response = await fetch('https://formspree.io/f/xovqjwyj', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(values),
        });
        if (response.ok) {
            alert('Message sent successfully!');
        } else {
            alert('Failed to send message.');
        }
    } catch (error) {
        console.error('Error:', error);
        alert('There was an error sending your message.');
    }
};

const ContactForm = () => {
    
    return (
        <Formik
            initialValues={{
                firstName: '',
                lastName: '',
                email: '',
                inquiry: '',
                message: ''
            }}
            validate={validateContactForm}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
                await sendFormData(values);
                setSubmitting(false);
                resetForm();
            }}
        >
            {({
                isSubmitting,
                isValid,
                dirty
            }) => (
                <Form>
                    <FormGroup row>
                        <Label md='3' lg='2'>First Name</Label>
                        <Col md='8' lg='9'>
                            <Field
                                className='form-control'
                                name="firstName"
                            />
                            <ErrorMessage name='firstName'>
                                {(msg) => <p className='text-danger'>{msg}</p>}
                            </ErrorMessage>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label md='3' lg='2'>Last Name</Label>
                        <Col md='8' lg='9'>
                            <Field
                                className='form-control'
                                name="lastName"
                            />
                            <ErrorMessage name='lastName'>
                                {(msg) => <p className='text-danger'>{msg}</p>}
                            </ErrorMessage>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label md='3' lg='2'>Email</Label>
                        <Col md='8' lg='9'>
                            <Field
                                className='form-control'
                                name="email"
                            />
                            <ErrorMessage name='email'>
                                {(msg) => <p className='text-danger'>{msg}</p>}
                            </ErrorMessage>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label md='3' lg='2'>Inquiry</Label>
                        <Col md='8' lg='9'>
                            <Field
                                as='select'
                                className='form-control'
                                name="inquiry"
                            >
                                <option value=''>--- click to select ---</option>
                                <option value='recruitment'>Recruitment</option>
                                <option value='services'>Commission</option>
                                <option value='consultation'>Consultation</option>
                                <option value='other'>Other</option>
                            </Field>
                            <ErrorMessage name='inquiry'>
                                {(msg) => <p className='text-danger'>{msg}</p>}
                            </ErrorMessage>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label md='3' lg='2'>Message</Label>
                        <Col md='8' lg='9'>
                            <Field
                                as='textarea'
                                className='form-control'
                                style={{ height: '100px'}}
                                maxlength='1000'
                                name="message"
                            />
                            <ErrorMessage name='message'>
                                {(msg) => <p className='text-danger'>{msg}</p>}
                            </ErrorMessage>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md='3' lg='2' className='offset-md-3 offset-lg-2'>
                            <Button
                                color={!(isValid && dirty) ? 'secondary' : 'primary' }
                                block
                                type='submit'
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? 'Sending...' : 'Send'}
                            </Button>
                        </Col>
                    </FormGroup>
                </Form>
            )}
        </Formik>
    )
}

export default ContactForm;