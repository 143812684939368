import { 
    Container,
    Row,
    Col,
    Image
} from 'react-bootstrap';
import ScrollToHashElement from '../utils/scrollToHashElement';

const Services = () => {
    return (
        <Container className='navbar-offset service-page px-0 w-100'>
            <ScrollToHashElement />
            <Row id='websites' className='w-100 py-3 m-0 d-flex align-items-center'>
                <div className='service-pg-h1 text-center'>Custom Websites</div>
                <Col xs='10' md='10' lg='8' xl='6' className='service-banner d-sm-flex align-items-center offset-1 offset-lg-2 offset-xl-3'>
                    <Col xs='12' sm='4' md='4' lg='5' xl='4' className='d-flex justify-content-center '>
                        <Image className='service-pg-img' src={require('../assets/images/webdev.png')} />
                    </Col>
                    <Col xs='12' sm='8' md='8' lg='7' xl='8'>
                        <p className='service-pg-h2 px-0 px-sm-2'>I create fully customized websites tailored to meet the unique needs of individuals, small businesses, or organizations.</p>
                        
                    </Col>
                </Col>
                <Col xs='10' md='10' lg='8' xl='6' className='offset-1 offset-lg-2 offset-xl-3 p-0 service-list'>
                    <Container className='service-list'>
                            <Row>
                                <Col xs='12' md='6' className='px-3 mobile-pt-2'>
                                    <ul>
                                        <li>Design and development of dynamic and responsive websites.</li>
                                        <li>Implementation mobile-friendly layouts and designs.</li>
                                        <li>Creation of wireframes and prototypes based on client needs.</li>
                                    </ul>
                                </Col>
                                <Col xs='12' md='6' className='px-3 mobile-pt-2'>
                                    <ul>
                                        <li>Options for CMS (Content Management Systems) integration for easy content updates.</li>
                                        <li>Optimized SEO and page loading performance.</li>
                                    </ul>
                                </Col>
                            </Row>
                    </Container>
                </Col>
            </Row>
            <Row id='mobileapp' className='w-100 py-3 m-0 d-flex align-items-center bg-light'>
                <div className='service-pg-h1 text-center'>Mobile App (Hybrid) Development</div>
                <Col xs='10' md='10' lg='8' xl='6' className='service-banner d-sm-flex align-items-center offset-1 offset-lg-2 offset-xl-3'>
                    <Col xs='12' sm='4' md='4' lg='5' xl='4' className='d-flex justify-content-center '>
                        <Image className='service-pg-img' src={require('../assets/images/mobileapp.png')} />
                    </Col>
                    <Col xs='12' sm='8' md='8' lg='7' xl='8'>
                        <p className='service-pg-h2 px-0 px-sm-2'>I develop mobile applications that work across both iOS and Android platforms using hybrid technologies like React Native.</p>
                        
                    </Col>
                </Col>
                <Col xs='10' md='10' lg='8' xl='6' className='offset-1 offset-lg-2 offset-xl-3 p-0 service-list'>
                    <Container className='service-list'>
                            <Row>
                                <Col xs='12' md='6' className='px-3 mobile-pt-2'>
                                    <ul>
                                        <li>Design and development of mobile applications using React Native.</li>
                                        <li>Implementation core app features like push notifications, geolocation, and in-app purchases.</li>
                                        <li>Connecting mobile apps with backend services and APIs.</li>
                                    </ul>
                                </Col>
                                <Col xs='12' md='6' className='px-3 mobile-pt-2'>
                                    <ul>
                                        <li>Cross-platform testing to ensure compatibility.</li>
                                        <li>Submition of apps to the App Store and Google Play Store.</li>
                                        <li>User account support and maintenance.</li>
                                    </ul>
                                </Col>
                            </Row>
                    </Container>
                </Col>
            </Row>
            <Row id='spa' className='w-100 py-3 m-0 d-flex align-items-center'>
                <div className='service-pg-h1 text-center'>Single Page Application (SPA) Development</div>
                <Col xs='10' md='10' lg='8' xl='6' className='service-banner d-sm-flex align-items-center offset-1 offset-lg-2 offset-xl-3'>
                    <Col xs='12' sm='4' md='4' lg='5' xl='4' className='d-flex justify-content-center '>
                        <Image className='service-pg-img' src={require('../assets/images/spa_alt.png')} />
                    </Col>
                    <Col xs='12' sm='8' md='8' lg='7' xl='8'>
                        <p className='service-pg-h2 px-0 px-sm-2'>I develop fast, responsive SPAs tailored to your business needs, whether it's for real-time data handling, dynamic content updates, or delivering a seamless user experience</p>
                    </Col>
                </Col>
                <Col xs='10' md='10' lg='8' xl='6' className='offset-1 offset-lg-2 offset-xl-3 p-0 service-list'>
                    <Container className='service-list'>
                            <Row>
                                <Col xs='12' md='6' className='px-3 mobile-pt-2'>
                                    <ul>
                                        <li>Development of SPAs using React for smooth, interactive user experiences.</li>
                                        <li>Implementation of routing designed to create a seamless navigation experience.</li>
                                        <li>Connection to RESTful APIs or GraphQL for real-time data updates.</li>
                                    </ul>
                                </Col>
                                <Col xs='12' md='6' className='px-3 mobile-pt-2'>
                                    <ul>
                                        <li>Optimization of SPA performance and load times.</li>
                                        <li>Options to debug and maintain SPA applications for continuous improvements.</li>
                                        <li>User account support and maintenance.</li>
                                    </ul>
                                </Col>
                            </Row>
                    </Container>
                </Col>
            </Row>
            <Row id='fullstack' className='w-100 py-3 m-0 d-flex align-items-center bg-light'>
                <div className='service-pg-h1 text-center'>Full-Stack Web Development</div>
                <Col xs='10' md='10' lg='8' xl='6' className='service-banner d-sm-flex align-items-center offset-1 offset-lg-2 offset-xl-3'>
                    <Col xs='12' sm='4' md='4' lg='5' xl='4' className='d-flex justify-content-center '>
                        <Image className='service-pg-img' src={require('../assets/images/fullstack.png')} />
                    </Col>
                    <Col xs='12' sm='8' md='8' lg='7' xl='8'>
                        <p className='service-pg-h2 px-0 px-sm-2'>I provide full-stack development services, handling both the frontend and backend aspects of your web application.</p>
                    </Col>
                </Col>
                <Col xs='10' md='10' lg='8' xl='6' className='offset-1 offset-lg-2 offset-xl-3 p-0 service-list'>
                    <Container className='service-list'>
                            <Row>
                                <Col xs='12' md='6' className='px-3 mobile-pt-2'>
                                    <ul>
                                        <li>Development of responsive frontend interfaces with React.</li>
                                        <li>Building scalable backend services with Node.js and Express.</li>
                                        <li>Designing and integrating SQL or MongoDB to store and retrieve data.</li>
                                    </ul>
                                </Col>
                                <Col xs='12' md='6' className='px-3 mobile-pt-2'>
                                    <ul>
                                        <li>Set up and configuration of user authentication, authorization, and security measures.</li>
                                        <li>User account support and maintenance.</li>
                                    </ul>
                                </Col>
                            </Row>
                    </Container>
                </Col>
            </Row>
            <Row id='cloud' className='w-100 py-3 m-0 d-flex align-items-center'>
                <div className='service-pg-h1 text-center'>Cloud Hosting and Website Hosting</div>
                <Col xs='10' md='10' lg='8' xl='6' className='service-banner d-sm-flex align-items-center offset-1 offset-lg-2 offset-xl-3'>
                    <Col xs='12' sm='4' md='4' lg='5' xl='4' className='d-flex justify-content-center '>
                        <Image className='service-pg-img' src={require('../assets/images/cloud.png')} />
                    </Col>
                    <Col xs='12' sm='8' md='8' lg='7' xl='8'>
                        <p className='service-pg-h2 px-0 px-sm-2'>I assist in deploying and managing your websites and web applications on cloud platforms like AWS and Google Firebase.</p>
                    </Col>
                </Col>
                <Col xs='10' md='10' lg='8' xl='6' className='offset-1 offset-lg-2 offset-xl-3 p-0 service-list'>
                    <Container className='service-list'>
                            <Row>
                                <Col xs='12' md='6' className='px-3 mobile-pt-2'>
                                    <ul>
                                        <li>Setting up cloud hosting environments.</li>
                                        <li>Setting up and configuring hosting/domains for websites.</li>
                                        <li>Managing databases and storage on cloud platforms.</li>
                                    </ul>
                                </Col>
                                <Col xs='12' md='6' className='px-3 mobile-pt-2'>
                                    <ul>
                                        <li>Configuration of server environments for optimal performance.</li>
                                        <li>Monitoring cloud resources to ensure scalability and uptime.</li>
                                        <li>Performing backup, security, and update management.</li>
                                    </ul>
                                </Col>
                            </Row>
                    </Container>
                </Col>
            </Row>
            <Row id='support' className='w-100 py-3 m-0 d-flex align-items-center bg-light'>
                <div className='service-pg-h1 text-center'>IT Support</div>
                <Col xs='10' md='10' lg='8' xl='6' className='service-banner d-sm-flex align-items-center offset-1 offset-lg-2 offset-xl-3'>
                    <Col xs='12' sm='4' md='4' lg='5' xl='4' className='d-flex justify-content-center '>
                        <Image className='service-pg-img' src={require('../assets/images/techsupport.png')} />
                    </Col>
                    <Col xs='12' sm='8' md='8' lg='7' xl='8'>
                        <p className='service-pg-h2 px-0 px-sm-2'>I provide personalized IT support tailored to individuals and small businesses who need reliable, affordable, and on-demand technology solutions.</p>
                    </Col>
                </Col>
                <Col xs='10' md='10' lg='8' xl='6' className='offset-1 offset-lg-2 offset-xl-3 p-0 service-list'>
                    <Container className='service-list'>
                            <Row>
                                <Col xs='12' md='6' className='px-3 mobile-pt-2'>
                                    <ul>
                                        <li>Troubleshooting hardware and software issues.</li>
                                        <li>Performing routine maintenance and updates on systems.</li>
                                        <li>Set up and configuration of networks, servers, and user devices.</li>
                                    </ul>
                                </Col>
                                <Col xs='12' md='6' className='px-3 mobile-pt-2'>
                                    <ul>
                                        <li>Implementation cybersecurity measures to protect business data.</li>
                                        <li>Options for ongoing technical support and training for staff.</li>
                                    </ul>
                                </Col>
                            </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    )
}

export default Services;