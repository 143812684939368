import Hero from '../features/Home/Hero';
import Intro from '../features/Home/Intro';
import ServicesList from '../features/Home/Services';

const Home = () => {
    return (
        <>
            <Hero />
            <Intro />
            <ServicesList />
        </>
    )
}

export default Home;