import { 
    Container,
    Row,
    Col,
    Image
} from 'react-bootstrap';
import AgeReadout from '../utils/ageReadout';

const About = () => {
    return (
        <Container className='navbar-offset'>
            <AgeReadout />
            <Row className='w-100 py-3 m-0'>
                <Col md='8' lg='4' xl='3' className='offset-md-2 offset-lg-2 offset-xl-3 text-justify'>I was born and raised in Sacramento, California. I have a life-long love of technology and art, which I have explored in many different ways throughout my life.</Col>
                <Col md='6' lg='4' xl='3' className='offset-md-3 offset-lg-0'>
                    <Image className='about-img' src={require('../assets/images/Pool/placeholder.webp')} />
                </Col>
            </Row>
            <Row className='w-100 py-3 m-0'>
                <Col md='6' lg='4' xl='3' className='offset-md-3 offset-lg-2 offset-xl-3'>
                    <Image className='about-img' src={require('../assets/images/Pool/placeholder.webp')} />
                </Col>
                <Col md='8' lg='4' xl='3' className='offset-md-2 offset-lg-0 offset-xl-0 text-justify'>My first career was building interactive technology for museum exhibits, which was a wonderful way to explore the intersection of my passions. My second career, which is in Information Technology, has been a deep dive into computer science and a rewarding learning experience, which lead me to become a web application developer.</Col>
            </Row>
            <Row className='w-100 py-3 m-0'>
                <Col md='8' xl='6' className='offset-md-2 offset-lg-2 offset-xl-3 text-justify'>I live with my girlfriend and our three cats. When I'm not working, I'm hiking or running in Pt Reyes, watching Only Murders In The Building, playing Super Nintendo, or having my butt kicked at Unbound Yoga.</Col>
            </Row>
            <Row>
                <Col xs='12' md='6' lg='4' xl='3' className='offset-md-2 offset-lg-2 offset-xl-3'>
                    <Image className='about-img' src={require('../assets/images/Pool/placeholder.webp')} />
                </Col>
                <Col xs='12' md='6' lg='4' xl='3' className='mobile-pt-5'>
                    <Image className='about-img' src={require('../assets/images/Pool/placeholder.webp')} />
                </Col>
            </Row>
            <Row className='w-100 py-3 m-0'>
                <Col md='8' lg='8' xl='6' className='offset-md-2 offset-lg-2 offset-xl-3 text-justify'>I have a tattoo commemorating my exhibit-building career and one that's from a Sarah's Scribbles comic. My favorite internet content is webcomics, cat videos, and memes about unconventional installations of the video game "Doom" on kitchen appliances.</Col>
            </Row>
            <Row className='w-100 py-3 m-0'>
                <Col md='8' lg='6' className='offset-md-2 offset-lg-3 text-justify'>Hi mom and dad!</Col>
            </Row>
        </Container>
    )
}

export default About;