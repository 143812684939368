import { Container, Row, Col, Image } from "react-bootstrap"
import { Link, useNavigate } from 'react-router-dom';

const ServiceList = () => {
    const navigate = useNavigate();
    const handleServiceButton = (url, event) => {
        event.preventDefault();
        setTimeout(() => {
        navigate(url);
        }, 300);
    };

    return (
        <Container className='service-container py-5'>
            <Row className="text-center">
                <Col xs='12' className='services-header'>
                    <span className="service-h1">
                        Services
                    </span>
                </Col>
            </Row>
            <Row className='text-center pt-4 service-row'>
                <Link to='/services#websites' onClick={(e) => handleServiceButton('/services#websites', e)} className='mb-3 mobile-mb-3 offset-1 offset-lg-0 col-5 col-md-5 col-lg-4 link-text-reset service-min-height service-link'>
                    <div className='service-h2 h-100 w-100 align-items-center d-flex justify-content-center service-col flex-column'>
                        <Image src={require('../../assets/images/webdev.png')} className='w-75 service-img'/>
                        <div className='service-img-overlay'>Custom Websites</div>
                    </div>
                </Link>
                <Link to='/services#mobileapp' onClick={(e) => handleServiceButton('/services#mobileapp', e)} className='mb-3 mobile-mb-3  offset-lg-0 col-5 col-md-5 col-lg-4 service-min-height link-text-reset service-link'>
                    <div className='service-h2 h-100 w-100 align-items-center d-flex justify-content-center service-col service-min-height flex-column'>
                        <Image src={require('../../assets/images/mobileapp.png')} className='w-75 service-img'/>
                        <div className='service-img-overlay'>Hybrid Mobile Apps</div>
                    </div>
                </Link>
                <Link to='/services#spa' onClick={(e) => handleServiceButton('/services#spa', e)} className='mb-3 mobile-mb-3 offset-1 offset-lg-0 col-5 col-md-5 col-lg-4 link-text-reset service-min-height service-link'>
                    <div className='service-h2 h-100 w-100 align-items-center d-flex justify-content-center service-col service-min-height flex-column'>
                        <Image src={require('../../assets/images/spa_alt.png')} className='w-75 service-img'/>
                        <div className='service-img-overlay'>Single Page Applications</div>
                    </div>
                </Link>
                <Link to='/services#fullstack' onClick={(e) => handleServiceButton('/services#fullstack', e)} className='mb-3 mobile-mb-3 offset-lg-0 col-5 col-md-5 col-lg-4 link-text-reset service-min-height service-link'>
                    <div className='service-h2 h-100 w-100 align-items-center d-flex justify-content-center service-col service-min-height flex-column'>
                        <Image src={require('../../assets/images/fullstack.png')} className='w-75 service-img'/>
                        <div className='service-img-overlay'>Full Stack Development</div>
                    </div>
                </Link>
                <Link to='/services#cloud' onClick={(e) => handleServiceButton('/services#cloud', e)} className='mb-3 mobile-mb-3 offset-1 offset-lg-0 col-5 col-md-5 col-lg-4 link-text-reset service-min-height service-min-height service-link'>
                    <div className='service-h2 h-100 w-100 align-items-center d-flex justify-content-center service-col service-min-height flex-column'>
                        <Image src={require('../../assets/images/cloud.png')} className='w-75 service-img'/>
                        <div className='service-img-overlay'>Cloud Hosting</div>
                    </div>
                </Link>
                <Link to='/services#support' onClick={(e) => handleServiceButton('/services#support', e)} className='mb-3 mobile-mb-3 offset-lg-0 col-5 col-md-5 col-lg-4 link-text-reset service-min-height service-link'>
                    <div className='service-h2 h-100 w-100 align-items-center d-flex justify-content-center service-col service-min-height flex-column'>
                        <Image src={require('../../assets/images/techsupport.png')} className='w-75 service-img'/>
                        <div className='service-img-overlay'>Tech Support</div>
                    </div>
                </Link>
            </Row>
        </Container>
    )
}

export default ServiceList;