import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToHashElement = () => {
  const location = useLocation();
  const navbarHeight = 101;

  useEffect(() => {
    const scrollWithOffset = () => {
      if (location.hash) {
        const elementId = location.hash;
        const element = document.querySelector(elementId);

        if (element) {
          setTimeout(() => {
            window.scrollTo({
              top: element.offsetTop - navbarHeight,
              behavior: "smooth",
            });
          }, 10);
        }
      }
    };
    scrollWithOffset();
  }, [location]);

  return null;
};

export default ScrollToHashElement;
