import { Row } from 'react-bootstrap';
import { Outlet } from 'react-router';
import NavBar from './NAVBAR';

const Layout = () => {
    return (
        <div className='Layout h-100 d-flex flex-column align-items-end'>
            <Row className='header'>
                <NavBar />
            </Row>
            <Row className='w-100 mx-0 outlet-row'>
                <Outlet />
            </Row>
            <Row className='mx-0 w-100 mt-auto'>
                <div className='footer'>2024 COPYRIGHT: GAVINKINDER.COM</div>
            </Row>
        </div>
    )
}

export default Layout;