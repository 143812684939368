import { 
    Container,
    Row,
    Col,
    Image
} from 'react-bootstrap';
import { motion } from 'framer-motion';

const Hero = () => {
    return (
        <Container className='hero-view align-content-center'>
            <Row className='justify-content-center align-content-center h-100'>
                <Col xs='12' lg='5' xl='6'>
                    <motion.div
                        className='landing-photo-div'
                        initial={{ opacity: 0, x: -30 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.75, ease: "easeOut" }}
                    >
                        <Image
                            className='landing-photo'
                            src={require("../../assets/images/landing_page_bird_selfie_comp.png")}
                            alt='A selfie of a man in the snow with a bird on his head.'
                            
                        />
                    </motion.div>
                </Col>
                <Col lg='3' xl='5' className='align-content-center justify-content-center'>
                    <Row>
                        <Col xs='12' lg='3'>
                            <motion.div
                                initial={{ opacity: 0, x: 30 }}
                                animate={{ opacity: 1, x: 0 }}
                                transition={{ duration: 0.75, ease: "easeOut" }}
                            >
                                <span className='hero-header-font'>Hi, I'm Gavin.</span>
                            </motion.div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default Hero;