import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

const NavBar = () => {
    return (
        <Navbar
            expand='lg'
            fixed='top'
            className='px-2 bg-white shadow-lg'
        >
                <Navbar.Brand className='logo'>
                    <a href='/'>GDK</a>
                </Navbar.Brand>
            <Navbar.Toggle aria-controls='navMenu' className='bg-light' />
            <Navbar.Collapse id='navMenu'>
                <Nav className='me-auto'>
                    <Nav.Link href='/'>Home</Nav.Link>
                    {/* <Nav.Link href='about'>About</Nav.Link> */}
                    <Nav.Link href='services'>Services</Nav.Link>
                    <Nav.Link 
                        href='./assets/files/Gavin Kinder - Resume (Oct 2024).pdf'
                        target='_blank'
                    >
                        Resume
                        <i className='fa-solid fa-up-right-from-square fa-xs' />
                    </Nav.Link>
                    <Nav.Link href='contact'>Contact</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default NavBar;