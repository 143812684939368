import { 
    Container,
    Row,
    Col
} from 'react-bootstrap';
import ContactForm from '../components/ContactForm';

const Contact = () => {
    return (
        <Container className='navbar-offset'>
            <Row className='w-100 py-3'>
                <Col xs='12' className='text-center'>
                    <h1>Contact Me</h1>
                </Col>
            </Row>
            <Row className='w-100'>
                <Col
                    xs='11'
                    md='8'
                    className='offset-1 offset-md-2'
                >
                    <ContactForm />
                </Col>
            </Row>
        </Container>
    )
}

export default Contact;