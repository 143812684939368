import { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

const AgeReadout = () => {
  const [age, setAge] = useState('');
  const birthdate = '1991-10-10';

  const getCaliforniaTime = () => {
    const now = new Date();
    return new Date(now.toLocaleString('en-US', { timeZone: 'America/Los_Angeles' }));
  };

  const calculateAge = () => {
    const now = getCaliforniaTime();
    const birthDate = new Date(birthdate);
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();

    let years = now.getFullYear() - birthDate.getFullYear();
    let months = now.getMonth() - birthDate.getMonth();
    let days = now.getDate() - birthDate.getDate();

    if (days < 0) {
      const lastDayOfPreviousMonth = new Date(now.getFullYear(), now.getMonth(), 0).getDate();
      days += lastDayOfPreviousMonth;
      months--;
    }
    if (months < 0) {
      months += 12;
      years--;
    }

    return { years, months, days, hours, minutes, seconds };
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const { years, months, days, hours, minutes, seconds } = calculateAge();
      setAge({
        years: years,
        months: months,
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds });
    }, 1000);

    return () => clearInterval(interval);
  }, [birthdate]);

  return (
    <Row className='text-center d-flex justify-content-around justify-content-sm-center p-2 border-bottom py-3 age-readout'>
        <span className='age-readout-h1'>Uptime</span>
        <Col xs='12' xl='8' className='d-flex justify-content-around clock-font'>
            <div className='clock-font'>{age.years} Years</div>
            <div>{age.months} Months</div>
            <div>{age.days} Days</div>
            <div>{age.hours} Hours</div>
            <div>{age.minutes} Minutes</div>
            <div>{age.seconds} Seconds</div>
        </Col>
    </Row>
  )
};

export default AgeReadout;
